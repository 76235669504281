<template>
	<div class="ysTeachVideo">
		<div id="mui-player"></div>
	</div>
</template>

<script>
	import 'mui-player/dist/mui-player.min.css'
	import MuiPlayer from 'mui-player'
	// 使用模块管理器引入插件
	import MuiPlayerDesktopPlugin from 'mui-player-desktop-plugin'
	// import customSetting from './customSetting.js'
	import Vue, { nextTick } from 'vue'
	import VueClipBoard from 'vue-clipboard2'
	Vue.use(VueClipBoard)

	export default {
		props:['videoUrl','shownum'],
		data() {
			return {
				mp:''
			}
		},
		watch:{
			videoUrl(val,oldval){
				this.myVideo(val);
			}
		},
		mounted() {
			var that = this
			if(this.shownum == 0){
				// 显示视频时
				this.myVideo(that.videoUrl);
			}
		},
		methods: {
			myVideo(videoUrl) {
				// 初始化构建播放器实例
				this.mp = new MuiPlayer({
					container: '#mui-player', //指定播放器容器
					title: '', //标题
					src: videoUrl, //视频播放的资源地址
					poster:'', // 封面图
					custom: {
						headControls: [{
							slot: 'castScreen', // 对应定义的 slot 值
							click: function(e) { // 按钮点击事件回调
								console.log('cast screen button click...');
							},
							style: {}, // 自定义添加控件样式
						}]
					},
					// 配置功能
					plugins: [
						new MuiPlayerDesktopPlugin({
							// customSetting, // 设置组配置
							contextmenu: [ // 右键菜单组配置
								// {
								// 	name: 'copyPlayUrl',
								// 	context: '复制视频链接',
								// 	zIndex: 0,
								// 	show: true,
								// 	click: function(close) {
								// 		// Action...
								// 		//复制内容
								// 		var src =
								// 			'https://muiplayer.oss-cn-shanghai.aliyuncs.com/static/media/media.mp4'
								// 		let oInput = document.createElement("input");
								// 		oInput.value = src;
								// 		document.body.appendChild(oInput);
								// 		oInput.select();
								// 		document.execCommand("Copy");
								// 		oInput.remove();
								// 		// 提示
								// 		mp.showToast('复制成功');
								// 	}
								// },
							],
							// thumbnails,  // 缩略图配置
						})
					],
				})
				// 监听播放器已创建完成
				this.mp.on('ready', () =>{
					// console.log('播放器已创建完成');
					this.playVideo();
				});
				this.mp.on('duration-change', function(duration) {
					// console.log('当视频时长已更改时触发', duration);
				});
				this.mp.on("controls-toggle", function(show) {
					// console.log('controls 控件状态变化时触发', show)
				})
				this.mp.on("seek-progress", function(percentage) {
					// console.log('当用户在视频寻址时触发', percentage)
				})

			},
			playVideo(){
				this.mp.video().play(); // 自动播放
			}
		},
		beforeDestroy() {
			// 销毁插件
		  if (this.mp) {
		    this.mp.video().pause();
		  }
		}
	}
</script>

<style lang="scss">
	.ysTeachVideo {
		width: 100%;
		height: 100%;
		// height: 730px;

		#mui-player {
			width: 100% !important;
			height: 100% !important;
		}
	}
</style>